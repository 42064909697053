import React from 'react';

function About() {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>About Me</h1>
      <p style={styles.paragraph}>
        Hello! My name is Yorman Aguirre, and I am passionate about web development, Kubernetes, and cloud technologies. 
        This is my GitHub Pages site built using React.
      </p>
      <p style={styles.paragraph}>
        Feel free to explore more about my projects and work!
      </p>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    color: '#333',
    fontSize: '36px',
    fontWeight: 'bold',
  },
  paragraph: {
    color: '#555',
    fontSize: '18px',
    lineHeight: '1.6',
  },
};

export default About;
