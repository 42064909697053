import logo from './logo.svg';
import './App.css';

import { Routes, Route} from "react-router-dom";
import About from "./routes/about";
import Navbar from './Navbar';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}
export default App;
